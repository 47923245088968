// import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AppHeader from "./components/layout/Header.jsx";
import AppFooter from "./components/layout/Footer";
import Home from "./components/Home/Home";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import store from "./store.js";
import { getAddress, loadUser } from "./actions/userAction.js";
// import SignIn from "./components/User/Login.js";
// import Signup from "./components/User/Signup.js";
import {
  getAllFeaturedProducts,
  getCategory,
} from "./actions/productAction.js";
// import ProductDetails from "./components/Products/ProductDetails.js";
import ProductDetailPage from "./components/Products/ProductDetailPage.js";
import { getCart } from "./actions/cartAction.js";
import CartPage from "./components/Cart/CartPage.js";
// import Checkout from "./components/Order/Checkout.jsx";
import Checkout from "./components/Order/Checkout.jsx";

import Wishlist from "./components/Wishlist/Wishlist.jsx";
import { loadFavourites } from "./actions/wishlistAction.js";
import ProfilePage from "./components/User/ProfilePage.jsx";
import EditAddressForm from "./components/User/EditAddressForm.jsx";
import AddAddressForm2 from "./components/User/AddAddressFrom2.jsx";
import ProductsPage from "./components/Search/SearchPage.jsx";
import OrderConfirmation from "./components/Order/orderConfirmation.jsx";
import Orders from "./components/Order/Orders.jsx";
import OrderDetails from "./components/Order/OrderDetails.jsx";
import SubmitReview from "./components/Products/ReviewProduct.js";
import ChangePassword2 from "./components/User/OTPAuth/ChangePassword.jsx";
import SignIn from "./components/User/Login.js";
import Signup from "./components/User/Signup.js";
import OTPSignup from "./components/User/OTPAuth/OTPSignup.jsx";
import SignupForm2 from "./components/User/Signup2.js";
import LoadtheboxEvent from "./components/Loadthebox/LoadtheboxEvent.jsx";
import OrderBox from "./components/Loadthebox/OrderBox.jsx";
import EventDetails from "./components/Loadthebox/EventDetails.jsx";
import InstaMojo from "./components/Order/InstaMojoTemp.js";
import OrdersError from "./components/Order/OrdersError.jsx";
import Termsandconditions from "./components/layout/Termsandconditions.jsx";
import Phonepe from "./components/layout/PhonePe.jsx";
import PaymentSuccess from "./components/Payment/PaymentSuccess.jsx";
import SurpriseBoxPage from "./components/SurpriseBox/SurpriseBox.jsx";

export const SECRET_KEY = "somekey";

// PROD
export const MEDIA_URL = "https://ktadmin.kitablovers.com/syradm";
export const BASE_URL = "https://backend.kitablovers.com";
// export const BASE_URL = "https://webbackend.kitablovers.com";

// TEST
// export const BASE_URL = "https://webbackendtest.kitablovers.com";
// export const BASE_URL = "http://localhost:4000";
// export const MEDIA_URL = "https://test.kitablovers.com/syradm";

function App() {
  const dispatch = useDispatch();

  // Dependency on path to trigger reload on change

  // console.log("isAuthenticated", user && user.userData);

  useEffect(() => {
    store.dispatch(loadUser());
    dispatch(loadUser());
    dispatch(getAllFeaturedProducts());
    dispatch(getCart());
    dispatch(loadFavourites());
    dispatch(getAddress());
    dispatch(getCategory());
    // dispatch(fetchOrders());
  }, [dispatch]);

  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     // window.location.href = "https://apps.apple.com/"; // Replace [YOUR_APP_ID] with your App Store app ID
  //   } else if (/android/i.test(userAgent)) {
  //     window.location.href =
  //       "https://play.google.com/store/apps/details?id=com.kitablovers.customer"; // Replace [YOUR_PACKAGE_NAME] with your Play Store package name
  //   }
  // }, []);

  return (
    <>
      <Router>
        <AppHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/surprisebox" element={<SurpriseBoxPage />} />

          <Route path="/product/:id" element={<ProductDetailPage />} />
          <Route path="/orderyourbox" element={<OrderBox />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/test" element={<InstaMojo />} />
          <Route path="/signup" element={<SignupForm2 />} />
          <Route exact path="/phonepetest" element={<Phonepe />} />
          <Route path="/otpsignup" element={<OTPSignup />} />
          <Route path="/loadtheboxevent" element={<LoadtheboxEvent />} />
          <Route path="/eventdetails" element={<EventDetails />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/editaddress" element={<EditAddressForm />} />
          <Route path="/address" element={<AddAddressForm2 />} />
          <Route path="/search" element={<ProductsPage />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orderconfirmation/error" element={<OrdersError />} />
          <Route path="/orderdetails/:id" element={<OrderDetails />} />
          <Route path="/paymentsuccess/:id" element={<PaymentSuccess />} />
          <Route path="/review/:id" element={<SubmitReview />} />
          <Route path="/resetpassword" element={<ChangePassword2 />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route
            path="/orderconfirmation/:id"
            element={<OrderConfirmation />}
          />
          <Route path="/preorder/:id" element={<EventDetails />} />
        </Routes>
      </Router>

      <AppFooter />
    </>
  );
}

export default App;
